import axios from "axios";
import { getAccessTokenFromStorage, setTokensInStorage } from "./principal";
// import { OcpApimSubscriptionKey, baseURL } from "../config/config";
import { notifier } from "@tintelligence/components";
import jwt_decode from "jwt-decode";
import { tokenIsNotExpired } from "./helper";
import { acquireTokenSilently } from "./auth-util";
import { IntlProvider } from "react-intl";

import { defualtLocale as locale, messages } from "../i18n/intlProviderWrapper";
import { indexOf } from "lodash";

const intlProvider = new IntlProvider({ locale, messages });
const apiMessages = intlProvider.props.messages[locale];

axios.interceptors.response.use(null, (error) => {
  const errorCode = error.response && error.response.status;
  const expectedError = errorCode >= 400 && errorCode <= 500;
  if (errorCode === 401) {
    notifier.warn(apiMessages.menu.apiCommonMessages.unauthorised);
  } else if (!expectedError) {
    console.error(error);
    return Promise.reject(error); //Check
  }
  return Promise.reject({ status: errorCode, data: error?.response?.data }); //Check
});

axios.interceptors.request.use(
  async (request) => {
    const access_token = getAccessTokenFromStorage();
    const access_token_expiry = jwt_decode(access_token).exp;
    if (!tokenIsNotExpired(access_token_expiry)) {
      const { emails } = jwt_decode(access_token);
      const { accessToken, idToken } = await acquireTokenSilently(emails[0]);
      setTokensInStorage(accessToken, idToken);
    }
    //request.url.indexOf('packagefile/packageFile') >0 ||
    // if(request.url.indexOf('api/packagefile/packageFiles')>0 ||request.url.indexOf("api/packagefile/addPackageFile")>0)
    // request.url = "https://localhost:44329" + request.url;
    // else
    request.url = process.env.REACT_APP_BASE_URL + request.url;
        
    request.headers.common[
      "Authorization"
    ] = `Bearer ${getAccessTokenFromStorage()}`;
    request.headers.common["Ocp-Apim-Subscription-Key"] =
      process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY;
    return request;
  },
  function (err) {
    console.log(err);
    notifier.warn("error");
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
