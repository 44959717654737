import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { Layout } from "../../components/ui";
import { msalApp, acquireTokenSilently } from "../../util/auth-util";
import {
  setTokensInStorage,
  getUserDetailsFromStorage,
  doesUserHaveAccesstoDataHubApplication,
  clearTokensFromStorage,
  isUserActive,
} from "../../util/principal.js";
import { loggedIn } from "../../store/auth/index.js";
import LoginHeader from "../../containers/TopBar/LoginHeader";
import { notifier } from "@tintelligence/components";
import { injectIntl } from "react-intl";
import { getLandingPageUrl } from "./../../services/logosAndAssetsService";

const { Content } = Layout;

const loginViaAAD = async (dispatch, messages) => {
  try {
    clearTokensFromStorage();
    const { accessToken, idToken } = await acquireTokenSilently();
    setTokensInStorage(accessToken, idToken);
    if (!doesUserHaveAccesstoDataHubApplication() || !isUserActive()) {
      notifier.error(messages.applicationAccessNotPresent, 0);
      setTimeout(() => {
        msalApp.logoutRedirect();
        clearTokensFromStorage();
      }, 5000);
      return;
    }
    dispatch(loggedIn(getUserDetailsFromStorage()));
    localStorage.removeItem("sso");
  } catch (e) {
    console.log(e);
    clearTokensFromStorage();
    localStorage.removeItem("sso");
  }
};

const Login = (props) => {
  const user = useSelector((state) => state.auth.user.userName);
  const messages = props.intl.messages.login.notifiers;
  const dispatch = useDispatch();
  const { state } = props.location;
  const isRedirectedFromLogout =
    state && state.from.pathname.includes("logout");
  const landingPageImageSourceUrl = getLandingPageUrl();

  useEffect(() => {
    localStorage.getItem("sso") && loginViaAAD(dispatch, messages);
  }, []);
  if (user)
    return (
      <Redirect
        to={
          state && !isRedirectedFromLogout
            ? state.from.pathname + state.from.search
            : "/"
        }
      />
    );
  else if (localStorage.getItem("byPassLogin")) {
    return <Redirect to={"/main/dispense/dispensePack"} />;
  } else
    return (
      <Layout>
        <LoginHeader onClick={() => loginViaAAD(dispatch, messages)} />
        <Layout>
          <Content style={{ height: "calc(100vh - 55px)" }}>
            <img
              style={{
                width: "100%",
                height: "100%",
                display: "block",
                objectFit: "cover",
              }}
              src={landingPageImageSourceUrl}
            />
          </Content>
        </Layout>
      </Layout>
    );
};

export default injectIntl(Login);
