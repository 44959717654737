import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const user = useSelector((state) => state.auth.user.userName);
  const location = useLocation();
  if (location.search.includes("sso=1")) location.search = "";
  const byPassLogin = localStorage.getItem("byPassLogin");
  return (
    <Route>
      {user || byPassLogin ? (
        <Component {...rest} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: location },
          }}
        />
      )}
    </Route>
  );
};

export default ProtectedRoute;
