import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../common/api";
import { notifier } from "@tintelligence/components";

export const getPackageFilesList = (fileType) => (dispatch, getState) => {
  const currentState = getState().entities.packageFiles;
  const fetchPackageFiles = async (fileType) => {
    dispatch(
      apiCallBegan({
        url: `/dm/api/packagefile/packageFiles?fileType=${fileType}`,
        method: "get",
        onSuccess: setPackageFilesList,
        fileType: fileType,
        onError: packageFileDataRequestFailed
        //onError: () => notifier.error("Could not fetch package Files Instances"),
      })
    );
  };
  if (!currentState || !currentState[fileType] || !currentState[fileType].length) {
    fetchPackageFiles(fileType);
  }
};

const packageFilesSlice = createSlice({
  name: "PackageFiles",
  initialState: { currentDate: new Date() },
  reducers: {
    setPackageFilesList: (packageFiles, action) => {
      const { fileType, data } = action.payload;
      if (data && data.length > 0) {
        const fileData = data.filter((d) => {
          if (d.fileType === fileType)
            return d
        });
        packageFiles[fileType] = [...fileData];
      }
      // if (packageFiles[fileType])
      //   packageFiles[fileType].splice(0, fileData.length, ...fileData);
      // else packageFiles[fileType] = fileData;
    },
    // deletePackageFile: (packageFiles, action) => {
    //   const { fileType, data } = action.payload;

    //   if (packageFiles[fileType]) {
    //     const fileData = packageFiles[fileType].filter((d) => {
    //       if (d.packageFileRowId !== data.packageFileRowId)
    //         return d
    //     });
    //     packageFiles[fileType] = [...fileData];
    //     //packageFiles[fileType].splice(0, fileData.length, ...fileData);
    //   }
    // },
    // updatePackageFile: (packageFiles, action) => {
    //   const { fileType, data } = action.payload;

    //   if (packageFiles[fileType]) {
    //     const fileData = packageFiles[fileType].map((d) => {
    //       if (d.packageFileRowId === data.packageFileRowId)
    //         return data
    //       else return d
    //     });
    //     packageFiles[fileType] = [...fileData];
    //     //packageFiles[fileType].splice(0, fileData.length, ...fileData);
    //   }
    //   else {
    //     packageFiles[fileType] = [data];
    //   }

    // },
    // clearCachePackageFilesOnRefresh: (packageFiles, action) => {
    //   const { fileType } = action.payload;
    //   if (packageFiles[fileType])
    //     delete packageFiles[fileType]
    //   //notifier.success('refresh...')
    // },
    packageFileDataRequestFailed: (fileData, action) => {
      notifier.warn('Error occured while fetching data')
    }
  },
});

export const { setPackageFilesList,  
   packageFileDataRequestFailed } = packageFilesSlice.actions;
export default packageFilesSlice.reducer;
