import { createSlice } from "@reduxjs/toolkit";
import { notifier } from "@tintelligence/components";
import { apiCallBegan } from "../common/api";

export const getDispensePackInstances = (isHardRefresh) => (dispatch, getState) => {
  const currentState = getState().entities.dispensePackInstances;
  const fetchDispenseInstances = async () => {
    dispatch(
      apiCallBegan({
        url: `/dm/api/publishpack/packInstances`,
        method: "get",
        onSuccess: setDispensePackInstances,
        onError: () => notifier.error("Could not fetch Dispense Instances"),
      })
    );
  };
  if ( isHardRefresh || !currentState.length) {
    fetchDispenseInstances();
  }
};

const dispensePackInstancesSlice = createSlice({
  name: "dispensePackInstances",
  initialState: [],
  reducers: {
    setDispensePackInstances: (dispensePackInstances, action) => {
      dispensePackInstances.splice(0, dispensePackInstances.length, ...action.payload.data);
    },
  },
});

export const { setDispensePackInstances } = dispensePackInstancesSlice.actions;
export default dispensePackInstancesSlice.reducer;
