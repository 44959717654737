import { Result, Layout, Menu, Upload, Form as AntdForm, Image,Flex,Divider } from "antd";

export {
  Result,
  Layout,
  Menu,
  Upload,
  AntdForm, 
  Image,
  Flex,Divider
};