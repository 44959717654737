import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../common/api";
import { notifier } from "@tintelligence/components";

export const getBrandList = () => (dispatch, getState) => {
  const currentState = getState().entities.brands;
  const fetchBrands = async () => {
    dispatch(
      apiCallBegan({
        url: `/dm/api/brand/brands`,
        method: "get",
        onSuccess: setBrandList,
        onError: () => notifier.error("Could not fetch Brand Instances"),
      })
    );
  };
  if (!currentState.length) {
    fetchBrands();
  }
};

const brandSlice = createSlice({
  name: "brands",
  initialState: [],
  reducers: {
    setBrandList: (brands, action) => {
        brands.splice(0, brands.length, ...action.payload.data);
    },
    clearBrandList: (brands, action) => {
      brands=[];
  },
  },
});

export const { setBrandList,clearBrandList } = brandSlice.actions;
export default brandSlice.reducer;
