export const Theme = {
  fontSizes: {
    small: {
      fontFamily: 'Montserrat',
      pFont: "normal normal 500 14px/16px Montserrat",
    },
    large: {
      fontFamily: 'Montserrat',
      pFont: "normal normal 500 14px/16px Montserrat",
    },
  },
  mode: {
    light: {
      mainBg: "#F4F4F3",
      primary: "#FFFFFF",
      secondary: "#f6921e",
      activeSecondary: "#d67a0e",
      primaryText: "#333333",
      secondaryText: "#FFFFFF",
    },
    dark: {
      mainBg: "#1A1A1A",
      primary: "#292828",
      secondary: "#f6921e",
      activeSecondary: "#d67a0e",
      primaryText: "#c3c3c3",
      secondaryText: "#FFFFFF",
    },
  },
};

