import { createSlice } from "@reduxjs/toolkit";
import { getJsonData } from "../../routes/packageFiles/util";
//import ControlData from "../../routes/packageFiles/TIDispenseSetting/defaultDispenseSettingTabAndControl.json"


export const dispenseSettingTabAndControlsUrl = `${process.env.REACT_APP_BLOB_STORAGE_URL}tintelligence-shared-assets/dispenseSettingTabAndControl/defaultDispenseSettingTabAndControl.json`;


export const getDispenseSettingTabAndControls = () => (dispatch, getState) => {
  const currentState = getState().entities.dispenseSettingStore.dispenseSettingTabAndControls;
  const fetchSettingTabAndControls = async () => {

    const settingData = await getJsonData(dispenseSettingTabAndControlsUrl);
    //  console.log('controll data1', ControlData)
   // var settingData=ControlData
    dispatch(setDispenseSettingTabAndControls({ data: settingData }))
  };
  if (!currentState) {
    fetchSettingTabAndControls();
  }
};

export const priceDefaultValue={
  priceModelSetting: {
    priceCalculationModel: "None",
    overrideAllowedFor: {
      colorantPrice: false,
      discount: false,
      productPrice: false,
      finalPrice: false,
      surcharge: false,
      tax: false,
    },
    limitsToBeChecked: {
      minPrice: false,
      maxDiscountPercentage: false,
      maxDiscountPercentageValue: null,
      maxPrice: false,
      maxSurchargePercentage: false,
      maxSurchargePercentageValue: null
    },
    additionFields: {
      surcharge: false,
      discount: false,
      tax: false
    },
    surchargeApplyTo: {
      colorant: false,
      //colorantSurchargeValue:null,
      maxColorantPrice: false,
      minColorantPrice: false,
      stdColorantPrice: false,
      product: false,
      //productSurchargeValue:null,
      maxProductPrice: false,
      minProductPrice: false,
      stdProductPrice: false
    },
    discountApplyTo: {
      colorant: false,
      colorantDiscountValue: null,
      maxColorantPrice: false,
      minColorantPrice: false,
      stdColorantPrice: false,
      product: false,
      productDiscountValue: null,
      maxProductPrice: false,
      minProductPrice: false,
      stdProductPrice: false
    },
    taxApplyTo: {
      colorant: false,
      colorantTaxValue: null,
      maxColorantPrice: false,
      minColorantPrice: false,
      stdColorantPrice: false,
      product: false,
      productTaxValue: null,
      maxProductPrice: false,
      minProductPrice: false,
      stdProductPrice: false
    },
    calculationOrderSequence: [
      {
        value: "product",
        sequence: 1
      },
      {
        value: "colorant",
        sequence: 2
      },
      {
        value: "productAndColorant",
        sequence: 3
      },
      {
        value: "tax",
        sequence: 4
      },
      {
        value: "surcharge",
        sequence: 5
      },
      {
        value: "discount",
        sequence: 6
      }
    ]
  },
  priceDisplaySetting: {
    priceTypesToDisplay: {
      stdPrice: false,
      stdPriceValue: null,
      maxPrice: false,
      maxPriceValue: null,
      minPrice: false,
      minPriceValue: null
    },
   
    "fieldsToDisplayName": [
      {
        "sequence": 1,
        "propName": "individualColorant",
        "customPropName": "individualColorantValue",
        "customNameValue": null,
        "nameValue": "Individual colorant",
        "isDisplay": false
      },
      {
        "sequence": 2,
        "propName": "totalColorantPrice",
        "customPropName": "totalColoantPriceValue",
        "customNameValue": null,
        "nameValue": "Total Colorant price",
        "isDisplay": false
      },
      {
        "sequence": 3,
        "propName": "productPrice",
        "customPropName": "productPriceValue",
        "customNameValue": null,
        "nameValue": "Product Price",
        "isDisplay": false
      },
      {
        "sequence": 4,
        "propName": "colorantProductPrice",
        "customPropName": "colorantProductPriceValue",
        "customNameValue": null,
        "nameValue": "Colorant + Product price",
        "isDisplay": false
      },
      {
        "sequence": 5,
        "propName": "colorantSurcharge",
        "customPropName": "colorantSurchargeValue",
        "customNameValue": null,
        "nameValue": "Colorant Surcharge",
        "isDisplay": false
      },
      {
        "sequence": 6,
        "propName": "productSurcharge",
        "customPropName": "productSurchargeValue",
        "customNameValue": null,
        "nameValue": "Product Surcharge",
        "isDisplay": false
      },
      {
        "sequence": 7,
        "propName": "surcharge",
        "customPropName": "surchargeValue",
        "customNameValue": null,
        "nameValue": "Surcharge",
        "isDisplay": false
      },
      {
        "sequence": 8,
        "propName": "productDiscount",
        "customPropName": "productDiscountValue",
        "customNameValue": null,
        "nameValue": "Product Discount",
        "isDisplay": false
      },
      {
        "sequence": 9,
        "propName": "colorantDiscount",
        "customPropName": "colorantDiscountValue",
        "customNameValue": null,
        "nameValue": "Colorant Discount",
        "isDisplay": false
      },
      {
        "sequence": 10,
        "propName": "discount",
        "customPropName": "discountValue",
        "customNameValue": null,
        "nameValue": "Discount",
        "isDisplay": false
      },
      {
        "sequence": 11,
        "propName": "priceBeforeTax",
        "customPropName": "priceBeforeTaxValue",
        "customNameValue": null,
        "nameValue": "Price before tax",
        "isDisplay": false
      },
      {
        "sequence": 12,
        "propName": "colorantTax",
        "customPropName": "colorantTaxValue",
        "customNameValue": null,
        "nameValue": "Colorant Tax",
        "isDisplay": false
      },
      {
        "sequence": 13,
        "propName": "productTax",
        "customPropName": "productTaxValue",
        "customNameValue": null,
        "nameValue": "Product Tax",
        "isDisplay": false
      },
      {
        "sequence": 14,
        "propName": "tax",
        "customPropName": "taxValue",
        "customNameValue": null,
        "nameValue": "Tax",
        "isDisplay": false
      },
      {
        "sequence": 15,
        "propName": "priceOverride",
        "customPropName": "priceOverrideValue",
        "customNameValue": null,
        "nameValue": "Price Override",
        "isDisplay": false
      },
      {
        "sequence": 16,
        "propName": "finalPriceAfterTax",
        "customPropName": "finalPriceAfterTaxValue",
        "customNameValue": null,
        "nameValue": "Final price after tax",
        "isDisplay": false
      }
    ]
  },
}

export const defaultDispenseSettingValue = {
  "fileName": null,
  "generalSetting": {
    "askForBarCode": false,
    "autoReturnHomeDelay": 2,
    "noInternetWarningDays": 30,
    "noInternetWarningLink": null,
    "fLinkEnabled": false
  },
  "formulaSetting": {
    "customerNameRequired": false,
    "matchStandardFormulaWithOwnFormula": false
  },
  "maintenanceSetting": {
    "cleanBrushReminderDays": 5,
    "dispenseCount": 10,
    "refillUsingBarCode": false,
    "unitOfCanisters": null,
    "litersInOneCanisterUnit":null,
    "refillFactor": 95,
    "refillBatches": [{
      "refillBatch": "1",
      "example": "1L"
    },
    {
      "refillBatch": "2.5",
      "example": "2.5L"
    }],
    "allowRefillToMaximum": true
  },
  priceModelSetting: {
    priceCalculationModel: "None",   
  },
  priceDisplaySetting: {
  
  },
  taskSchedulerSetting: [
    // {
    //   "taskType": "Backup",
    //   "startDateTime": new Date().toUTCString(),
    //   "endDate": new Date().toUTCString(),
    //   "comment": "Take Backup",
    //   "recurrence": "daily",
    //   "connection": "typeSettingIndustry",
    //   "extraMessage": "showMessage"
    // },    
  ],
  noteSetting: {
    note: ""
  },
  operatorActions: {
    "overrideAllowedFor": {
      "priceFile": true,
      "printAction": true
    },
    "display": {
      "priceEditorShortcut": true,
      "formulaScalingAtDispense": false,
      "collectionFieldForCustomFormula":false
    }
  }
}

const dispenseSettingSlice = createSlice({
  name: "DispenseSettingProps",
  initialState: {
    dispenseSetting: defaultDispenseSettingValue,
    saveDispenseSettingStart: false,
    dispenseSettingTabAndControls: undefined,
    currentDate: new Date()
  },
  reducers: {
    setDispenseSetting: (dispenseSettingProps, action) => {
      const { settingType, data, parentProp } = action.payload;
      if (parentProp)
        dispenseSettingProps.dispenseSetting[parentProp] = { ...data };
      else if (settingType)
        dispenseSettingProps.dispenseSetting[settingType] = data;
      else
        dispenseSettingProps.dispenseSetting = { ...data }
    },
    setDefaultSettingValue: (dispenseSettingProps, action) => {
      const { data } = action.payload;
      if (data) {
        dispenseSettingProps.dispenseSetting = { ...data };
      } else {
        dispenseSettingProps.dispenseSetting = { ...defaultDispenseSettingValue };
      }
      dispenseSettingProps.saveDispenseSettingStart = false;
      dispenseSettingProps.currentDate = new Date()

    },
    setSaveDispenseSettingStart: (dispenseSettingProps, action) => {
      const { settingType, data, parentProp } = action.payload;
      dispenseSettingProps.saveDispenseSettingStart = data
    },
    setDispenseSettingTabAndControls: (dispenseSettingProps, action) => {
      const { data } = action.payload;
      dispenseSettingProps.dispenseSettingTabAndControls = data;
      //console.log('setDispenseSettingTabAndControls',data)
    }
  },
});



export const { setDispenseSetting, setDefaultSettingValue, setSaveDispenseSettingStart, setDispenseSettingTabAndControls } = dispenseSettingSlice.actions;
export default dispenseSettingSlice.reducer;
