import { getDownloadUrlForAFile } from "./../util/blobStorageService";

const CONTAINER_NAME = "tintelligence-assets";
const SHARED_CONTAINER_NAME = "tintelligence-shared-assets";

export const getImageForAMachineType = async (machineType) => {
  const url = await getDownloadUrlForAFile(
    CONTAINER_NAME,
    `machineTypes`,
    `${machineType}.png`
  );
  return url;
};

export const getLogoForACompany = async (companyName) => {
  const logoName =
    companyName == "OEM@AsianPaints" ? "logo-placeholder" : companyName;
  const url = await getDownloadUrlForAFile(
    CONTAINER_NAME,
    `logos`,
    `${logoName}.png`
  );
  return url;
};

export const getLandingPageUrl = () => {
  // const url = `${process.env.REACT_APP_BLOB_STORAGE_URL}${SHARED_CONTAINER_NAME}/landingPage/DataHub.jpg`;
  const url = `${process.env.REACT_APP_CDN_BLOB_STORAGE_URL}tintelligence-shared-assets/landingPage/DataHub.jpg`;

  return url;
};
