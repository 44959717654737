import { createSlice } from "@reduxjs/toolkit";

const getColumnOptionsForManage = () => {
  let options = [];
  if (localStorage.getItem("manageColumnOptions") === null) {
    localStorage.setItem("manageColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("manageColumnOptions"));
  return options;
};

const getColumnOptionsForPublish = () => {
  let options = [];
  if (localStorage.getItem("publishColumnOptions") === null) {
    localStorage.setItem("publishColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("publishColumnOptions"));
  return options;
};

const getColumnOptionsForFormulas = (fileType) => {
  let options = [];
  if (localStorage.getItem(fileType+"ColumnOptions") === null) {
    localStorage.setItem(fileType+"ColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem(fileType+"ColumnOptions"));
  return options;
};
const getColumnOptionsForAdminPanel = () => {
  let options = [];
  if (localStorage.getItem("adminPanelColumnOptions") === null) {
    localStorage.setItem("adminPanelColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("adminPanelColumnOptions"));
  return options;
};
const getColumnOptionsForDispensePacks = () => {
  let options = [];
  if (localStorage.getItem("dispensePacksColumnOptions") === null) {
    localStorage.setItem("dispensePacksColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("dispensePacksColumnOptions"));
  return options;
};

const getColumnOptionsForDispensePacksPackgeFile = () => {
  let options = [];
  if (localStorage.getItem("dispensePacksPackgeFileColumnOptions") === null) {
    localStorage.setItem(
      "dispensePacksPackgeFileColumnOptions",
      JSON.stringify(options)
    );
  }
  options = JSON.parse(
    localStorage.getItem("dispensePacksPackgeFileColumnOptions")
  );
  return options;
};

const getColumnOptionsForDispenseInstanceModal = () => {
  let options = [];
  if (localStorage.getItem("dispenseInstanceModalColumnOptions") === null) {
    localStorage.setItem(
      "dispenseInstanceModalColumnOptions",
      JSON.stringify(options)
    );
  }
  options = JSON.parse(
    localStorage.getItem("dispenseInstanceModalColumnOptions")
  );
  return options;
};
const getColumnOptionsForDispenseInstance = () => {
  let options = [];
  if (localStorage.getItem("dispenseInstanceColumnOptions") === null) {
    localStorage.setItem(
      "dispenseInstanceColumnOptions",
      JSON.stringify(options)
    );
  }
  options = JSON.parse(localStorage.getItem("dispenseInstanceColumnOptions"));
  return options;
};
const getColumnOptionsForSoftwareVersions = () => {
  let options = [];
  if (localStorage.getItem("softwareVersionColumnOptions") === null) {
    localStorage.setItem(
      "softwareVersionColumnOptions",
      JSON.stringify(options)
    );
  }
  options = JSON.parse(localStorage.getItem("softwareVersionColumnOptions"));
  return options;
};
const getColumnOptionsForBrands = () => {
  let options = [];
  if (localStorage.getItem("brandsColumnOptions") === null) {
    localStorage.setItem("brandsColumnOptions", JSON.stringify(options));
  }
  options = JSON.parse(localStorage.getItem("brandsColumnOptions"));
  return options;
};
const columnOptionsSlice = createSlice({
  name: "columnOptions",
  initialState: {
    publish: { columns: getColumnOptionsForPublish() },
    manage: { columns: getColumnOptionsForManage() },
   // packgeFileColumnOption: { columns: getColumnOptionsForFormulas() },

    packgeFormulaFileColumnOption: { columns: getColumnOptionsForFormulas("Formula") },
    packgeImageFileColumnOption: { columns: getColumnOptionsForFormulas("Image") },
    packgePriceFileColumnOption: { columns: getColumnOptionsForFormulas("Price") },
    packgeLabelFileColumnOption: { columns: getColumnOptionsForFormulas("Label") },


    admin: { columns: getColumnOptionsForAdminPanel() },
    dispensePacks: { columns: getColumnOptionsForDispensePacks() },
    dispensePacksPackgeFileColumnOption: {
      columns: getColumnOptionsForDispensePacksPackgeFile(),
    },
    dispenseInstanceModal: {
      columns: getColumnOptionsForDispenseInstanceModal(),
    },
    dispenseInstance: { columns: getColumnOptionsForDispenseInstance() },
    softwareVersion: { columns: getColumnOptionsForSoftwareVersions() },
    brands: { columns: getColumnOptionsForBrands() },
  },
  reducers: {
    setColumnsOptions: (colState, action) => {
      const { type, columns = [],fileType } = action.payload;
      if (!colState[type]) colState[type] = {};
      colState[type].columns = columns;
      type === "manage" &&
        localStorage.setItem("manageColumnOptions", JSON.stringify(columns));
      type === "publish" &&
        localStorage.setItem("publishColumnOptions", JSON.stringify(columns));
      type === "brands" &&
        localStorage.setItem("brandsColumnOptions", JSON.stringify(columns));
      type === "softwareVersion" &&
        localStorage.setItem(
          "softwareVersionColumnOptions",
          JSON.stringify(columns)
        );
    },
  },
});

export const { setColumnsOptions } = columnOptionsSlice.actions;
export default columnOptionsSlice.reducer;
