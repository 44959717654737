import { combineReducers } from "redux";
import machineSeries from "./machineSeries";
import servicePackStatus from "./servicePackStatus";
import databasePacks from "./databasePacks";
import databasePackStatus from "./databasePackStatus";
import shopGroups from "./shopGroups";
import colorantSystems from "./colorantSystems";
import dispenseInstances from "./dispenseInstances";
import packageFiles from "./packageFile";
import brands from "./brands"
import dispensePackInstances from "./dispensePackInstances";
import customerInfo from "./customer";
import userSessions from "./userSessions";
import dispenseSettingStore from "./dispenseSettingStore";

export default combineReducers({
  machineSeries,
  servicePackStatus,
  databasePacks,
  databasePackStatus,
  shopGroups,
  colorantSystems,
  dispenseInstances,
  packageFiles,
  brands,
  dispensePackInstances,
  customerInfo,
  userSessions,
  dispenseSettingStore
});
