import { createSlice } from "@reduxjs/toolkit";
import { notifier } from "@tintelligence/components";
import { apiCallBegan } from "../common/api";

export const getShopGroups = () => (dispatch, getState) => {
  const currentState = getState().entities.shopGroups;
  const fetchShopsGroups = async () => {
    const machineSeriesId = 0;
    dispatch(
      apiCallBegan({
        url: `/om/api/shopgroup/shopGroupHierarchy`,
        method: "get",
        onSuccess: setShopGroups,
        onError: () => notifier.error("Could not fetch Shop Groups"),
      })
    );
  };
  if (!currentState.length) {
    fetchShopsGroups();
  }
};

const shopGroupsSlice = createSlice({
  name: "shopGroups",
  initialState: [],
  reducers: {
    setShopGroups: (shopGroups, action) => {
      shopGroups.splice(0, shopGroups.length, ...action.payload.data);
    },
    setColorantsList: (databaseCommon, action) => {
      databaseCommon.colorantSystem = [...action.payload];
    }
  },
});

export const { setShopGroups } = shopGroupsSlice.actions;
export default shopGroupsSlice.reducer;
