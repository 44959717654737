import { createSlice } from "@reduxjs/toolkit";
import { notifier } from "@tintelligence/components";
import { apiCallBegan } from "../common/api";

export const getDispenseInstances = () => (dispatch, getState) => {
  const currentState = getState().entities.dispenseInstances;
  const fetchDispenseInstances = async () => {
    dispatch(
      apiCallBegan({
        url: `/dm/api/databasemanagement/dispenseInstances`,
        method: "get",
        onSuccess: setDispenseInstances,
        onError: () => notifier.error("Could not fetch Dispense Instances"),
      })
    );
  };
  if (!currentState.length) {
    fetchDispenseInstances();
  }
};

const dispenseInstancesSlice = createSlice({
  name: "dispenseInstances",
  initialState: [],
  reducers: {
    setDispenseInstances: (dispenseInstances, action) => {
      dispenseInstances.splice(0, dispenseInstances.length, ...action.payload.data);
    },
  },
});

export const { setDispenseInstances } = dispenseInstancesSlice.actions;
export default dispenseInstancesSlice.reducer;
