import moment from "moment";
import DOMPurify from 'dompurify';
import { debounce, isEmpty, isEqual } from "lodash";

export const compareDateStings = (d1, d2) => {
  const dateA = moment(d1, "DD/MM/YYYY"),
    dateB = moment(d2, "DD/MM/YYYY");
  return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
};

export const formatDate = (date, format) => {
  //return moment(date, format);
  return moment(date).format(format);
};

export const compareFormatedDates = (d1, d2, format) => {
  //console.log('compareFormatedDates', d1, d2, format);
  if (d1 == null || d2 == null) {
    return -1;
  }
  let dateA = moment(d1, format),
    dateB = moment(d2, format);
  if (!dateA.isValid() || !dateB.isValid()) {
    d1 = moment(d1).format(format);
    d2 = moment(d2).format(format);
    dateA = moment(d1, format)
    dateB = moment(d2, format);
  }
  //console.log('compareFormatedDates-count', dateA > dateB ? 1 : dateA < dateB ? -1 : 0);

  return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
};

export const scrollComponents = (parent, scroll) => {
  const el = parent.target,
    scrollTop = el.scrollTop;

  el.querySelectorAll(scroll).forEach((child) => {
    child.style.transform = `translateY(${scrollTop}px)`;
  });
};

export const tokenIsNotExpired = (exp) => Number(exp) * 1000 > Date.now();

export const formatMachineTypeForImage = (machine) => machine.toUpperCase().replace(/[|~\/]/g, "-");

export const formatBytes = (bytes, decimals = 2) => {
  if (!bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const  getFileExtension=(filename)=>
  {
    if(filename)
    {
      var ext = /^.+\.([^.]+)$/.exec(filename);
      return ext == null ? "" : `.${ext[1]}`;
    }
    else
    return ""
    
  }

  export const  removeFileExtension=(filename)=> {
    if(filename)
    return filename.replace(/\.[^\/.]+$/, '');
    else
     return ""
  }

export const isFileNameValidForWindow = (filename) => {
  let isValidFileName = true;
  if (filename) {
    try {
      const regex = new RegExp(/[<>:"\/\\|?*]/g)
      isValidFileName = !regex.test(filename);
    } catch (error) {
    }
  }
  return isValidFileName;
}

export const xssValid = (value) => {
  if (!isEmpty(value) && !isEqual(value, DOMPurify.sanitize(value))) {
    return false;
  }
  return true;
}

export const convertNumberFormat = (userSettings,num) => {
  return num.toLocaleString(userSettings.language, {
    minimumFractionDigits: userSettings.numberFormat? userSettings.numberFormat : 1,
    maximumFractionDigits: userSettings.numberFormat? userSettings.numberFormat : 1,
  })
}

export const getAllNumberFormatByLocale =  (locale) => {
  const num = 30000.65;
  const numbersArray = []
  for(let i=0;i<3;i++){
    numbersArray.push(num.toLocaleString(locale, {minimumFractionDigits: i, maximumFractionDigits: i}))
  }
  
  
  let data =  convertToHash(numbersArray).map((element , key) => {
    return {
      label : element,
      value : key,
      key:key
    }
  });
  
 return data;
}
const convertToHash = (numbersArray) =>{
  const hashedArray = []
  numbersArray.map((x)=>{
    hashedArray.push(x.replace(/[0-9]/g, '#'));
  })
  return hashedArray;
}

export const formatDateBasedOnUser = (value,userSettings) => {
  const fomatedValue = value && userSettings ? moment.utc(value).tz(userSettings.timeZone)?.format(userSettings.dateFormat + (userSettings.displayTimezone ? " z" : "")) : null
  return fomatedValue;
}
export const formatNumberBasedOnUser = (value, userSettings) => {
  const fomatedValue = value.toLocaleString(userSettings.language, {
    minimumFractionDigits: userSettings.numberFormat,
    maximumFractionDigits: userSettings.numberFormat,
  });
  return fomatedValue;
}

export const setFocusOnContentLoad = (container = '.ant-layout-content') => {
  window.setTimeout(() => {
    const layout = document.querySelector(container);
    console.log("focus layout container- ", container)
    if (layout) {
      // check any input type
      const inputs = document.querySelectorAll(`${container} input[type=text]:not(:disabled), ${container} input[type=search]:not(:disabled)`);
      const textArea = document.querySelectorAll(`${container} textarea:not(:disabled)`);
      const closeDrawerIcon = document.querySelectorAll(`${container} .ant-drawer-close`);
      const closeModalIcon = document.querySelectorAll(`${container} .ant-modal-close`);
      const buttons = document.querySelectorAll(`${container} .ant-btn:not(:disabled)`);

      if (inputs && inputs.length > 0) {
        // console.log("focus layout input found")
        // console.log("focus input", inputs[0])
        inputs[0].focus();
      }
      else if (textArea && textArea.length >0) {
        // check textarea
        textArea[0].focus();
      }
      else if (closeDrawerIcon && closeDrawerIcon.length == 1) {
        // check drawer close button
        closeDrawerIcon[0].focus();
      }
      else if (closeModalIcon && closeModalIcon.length == 1) {
        // check modal close button
        closeModalIcon[0].focus();
      }
      else if (buttons && buttons.length > 0) {
        // check button
        buttons[0].focus();
      } else {
        //console.log("focus layout not found")
      }
    }
  }, 500)
}


export const getShopGroupTreeData = (shopGroupsArray) => {
  let idPositionMap = {},
    group,
    nestedGroups = [],
    i,
    shopGroups = shopGroupsArray.map((g) => ({ ...g }));

  for (i = 0; i < shopGroups.length; i++) {
    idPositionMap[shopGroups[i].shopGroupId] = i; // initialize the map
    shopGroups[i].children = []; // initialize the children
  }

  for (i = 0; i < shopGroups.length; i++) {
    group = shopGroups[i];
    if (group.parentId !== 0) {
      // if you have dangling branches check that map[node.parentId] exists
      shopGroups[idPositionMap[group.parentId]].children.push(group);
    } else {
      nestedGroups.push(group);
    }
  }
  return getTreeDataFromShopGroups(nestedGroups);
};

const getTreeDataFromShopGroups = (nestedGroups) => {
  return nestedGroups.map((group) => ({
    key: group.shopGroupId,
    value: group.shopGroupId?.toString(),
    title: group.shopGroupName,
    children: getTreeDataFromShopGroups(group.children),
  }));
};

export const getShopGroupFilterTree = (shopGroupsArray) => {
  let idPositionMap = {},
    group,
    nestedGroups = [],
    i,
    shopGroups = shopGroupsArray.map((g) => ({ ...g }));

  for (i = 0; i < shopGroups.length; i++) {
    idPositionMap[shopGroups[i].shopGroupId] = i; // initialize the map
    shopGroups[i].children = []; // initialize the children
  }

  for (i = 0; i < shopGroups.length; i++) {
    group = shopGroups[i];
    if (group.parentId !== 0) {
      // if you have dangling branches check that map[node.parentId] exists
      shopGroups[idPositionMap[group.parentId]].children.push(group);
    } else {
      nestedGroups.push(group);
    }
  }
  return getFilterTreeShopGroups(nestedGroups);
};

const getFilterTreeShopGroups = (nestedGroups) => {
  return nestedGroups.map((group) => ({
    value: group.shopGroupName,
    text: group.shopGroupName,
    children: getFilterTreeShopGroups(group.children),
  }));
};