import { createSlice } from "@reduxjs/toolkit";
import { Theme } from "../../theme/theme";

const userPreferencesSlice = createSlice({
  name: "userPreferences",
  initialState: {
    theme: { font: "small", themeMode: "light" },
    dateFormat: "DD/MM/YYYY",
    dateTimeFormat: "DD/MM/YYYY h:mm:ss a",
  },
  reducers: {
    setTheme: (userPreferences, action) => {
      userPreferences.theme = action.payload;
    },
    setDateFormat: (userPreferences, action) => {
      const { state } = action.payload;
      userPreferences.dateFormat = state;
    },
    setDateTimeFormat: (userPreferences, action) => {
      const { state } = action.payload;
      userPreferences.dateTimeFormat = state;
    },
  },
});

export const {
  setTheme,
  setDateFormat,
  setDateTimeFormat,
} = userPreferencesSlice.actions;
export default userPreferencesSlice.reducer;
