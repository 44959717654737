import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "../../components/common/ProtectedRoute";
import { useSelector } from "react-redux";
import { ComponentThemeProviderCombine, Spinner } from "@tintelligence/components";
import { ThemeProvider } from "styled-components";
import { Theme } from "../../theme/theme";
import Login from "./../../routes/pages/Login";
import GlobalStyle from "../../theme/globalStyles";

const MainApp = React.lazy(() => import("./MainApp"));

const LazyMainApp = (props) => {
  return (
    <div>
      <Suspense fallback={<Spinner />}>
        <MainApp {...props} />
      </Suspense>
    </div>
  );
};

const App = (props) => {
  const { match, location, ...rest } = props;
  const theme = useSelector((state) => state.ui.userPreferences.theme);
  const { fontSizes, mode } = Theme;
  const { font, themeMode } = theme;
  const currentTheme = { font: fontSizes[font], mode: mode[themeMode] };

  const queryParams = new URLSearchParams(location.search);
  const sso = queryParams.get("sso");
  sso && localStorage.setItem("sso", "1");
  
  return (
    <ThemeProvider theme={currentTheme}>
      <ComponentThemeProviderCombine theme={currentTheme}>
        <GlobalStyle />
        <Switch>
          <Route exact path="/login" component={Login} />
          <ProtectedRoute component={LazyMainApp} {...rest} />
        </Switch>
      </ComponentThemeProviderCombine>
    </ThemeProvider>
  );
};

export default App;
