import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const userSessionsSlice = createSlice({
    name: "userSessions",
    initialState: {
        filters: {
            dispensePack: {},
            dispensePackInstance: {},
            dispensePackPackageFile: {},
            softwareVersion: {},
            brands: {},
            softwareUpdate: {}
        },
        language:null,
        userPreferences:{            
            timeZone:"",
            dateFormat:"",
            numberFormat:"",
            timeFormat: "hh:mm"
        }
    },
    reducers: {
        setFilters: (userSessions, action) => {
            const { page, fileType, filters, sorter } = action.payload;
            if (fileType) {
                if (!userSessions.filters[page][fileType]) {
                    userSessions.filters[page][fileType] = {
                        filters: filters,
                        sorter: sorter
                    }
                } else {
                    userSessions.filters[page][fileType].filters = filters;
                    userSessions.filters[page][fileType].sorter = sorter;
                }
            } else {
                userSessions.filters[page].filters = filters;
                userSessions.filters[page].sorter = sorter;
            }

        },
        setUserLanguage: (userSessions, action) => {
            userSessions.language = action.payload;
        },
        setUserPreferences: (userSessions,action) => {
            const { timeZone } = action.payload;
            if(!timeZone){
              action.payload.timeZone = moment.tz.guess()
            }
            userSessions.userPreferences = action.payload
        }
    }
});

export const { setFilters,setUserLanguage, setUserPreferences } = userSessionsSlice.actions;
export default userSessionsSlice.reducer;