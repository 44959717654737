import { createSlice } from "@reduxjs/toolkit";

const databasePacksSlice = createSlice({
  name: "databasePacks",
  initialState: { list: [] },
  reducers: {
    setDatabasePacksList: (databasePacks, action) => {
      databasePacks.list = [...action.payload];
    },
  },
});

export const { setDatabasePacksList } = databasePacksSlice.actions;
export default databasePacksSlice.reducer;
