import { createSlice } from "@reduxjs/toolkit";

const databasePackStatusSlice = createSlice({
  name: "databasePackStatus",
  initialState: { list: [] },
  reducers: {
    setDatabasePackStatusList: (databasePackStatus, action) => {
      databasePackStatus.list = action.payload;
    },
  },
});

export const { setDatabasePackStatusList } = databasePackStatusSlice.actions;
export default databasePackStatusSlice.reducer;
