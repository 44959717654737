import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
*{
    font-family: ${(props) => props.theme.font.fontFamily};
}
    & a {
        color: ${(props) => props.theme.mode.secondary};
        :hover {
            color: ${(props) => props.theme.mode.secondary};
        }
    }
    & .ant-drawer-title {
        font-size: 22px;
    }
    & .ant-layout-sider-children {
        background: ${(props) => props.theme.mode.secondary};
        background-image: url("/Left_Graphic_BG.svg");
        background-position: bottom;
        background-repeat: no-repeat;
        flex: 0 0 65px !important;
        max-width: 65px !important;
        min-width: 65px !important;
        width: 65px !important;

    }
    & .appsDropDownContainer {
        & .ant-dropdown-arrow {
            border-top-color: ${(props) => props.theme.mode.secondary};
            border-left-color: ${(props) => props.theme.mode.secondary};
        } 
        & .ant-dropdown-menu.ant-dropdown-menu-root {
            background-color: ${(props) => props.theme.mode.secondary};
        }
        & .ant-dropdown-menu-item.ant-dropdown-menu-item-active {
            background-color: ${(props) => props.theme.mode.activeSecondary};
        } 
    }
    & .ant-select-tree-list-holder {
        overflow: auto !important;
     }  
     & .ant-select-tree-list-scrollbar-thumb {
        display: none;
     }
   
     & .ant-modal-confirm-btns .ant-btn{
        background-color: rgb(246, 146, 30);
        border: 1px solid rgb(246, 146, 30);
        color: rgb(255, 255, 255);
     }
     & 
     .ant-modal-confirm-btns .ant-btn:hover, .ant-modal-confirm-btns .ant-btn:focus,
      .ant-modal-confirm-btns .ant-btn:active {
        background-color: rgb(214, 122, 14);
        border: 1px solid rgb(214, 122, 14);
        color: rgb(255, 255, 255);
     }

     & .ant-table-filter-dropdown-tree {
        & .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
            background-color: ${(props) => props.theme.mode.secondary};
        }

        & .ant-tree .ant-tree-treenode:not(.ant-tree .ant-tree-treenode-disabled).filter-node .ant-tree-title {
            color: ${(props) => props.theme.mode.secondary};
        }
     }
     &.ant-select-dropdown-hidden {
            display: block;        
            visibility: hidden;
         }
         & .tooltip-topRight-arrow-hide.ant-tooltip-placement-topRight .ant-tooltip-arrow
         {
            //display:none;
            //right: -3px;
         }
         & #table-icons .anticon:hover { & svg, & svg > path { 
            fill: ${(props) => props.theme.mode.secondary} 
        }}
        .ant-form-item .ant-form-item-explain-error {
            white-space: break-spaces;
        }

        .ant-tooltip{
            z-index:99 !important
        }

// FOCUS-VISBLE CSS START

        div:focus-visible, img:focus-visible, span[role='img']:focus-visible 
        ,span[role='img']:focus-visible
        {
            outline:${(props) => `${props.theme.mode.secondary} auto 1px`};
            outline-offset: 1px;
        }

        a:focus-visible, h2:focus-visible
        ,button:not(.ant-drawer-footer .ant-btn-primary):focus-visible,
        .ant-btn-primary:not(.ant-drawer-footer .ant-btn-primary):focus-visible, 
        .ant-btn-secondary:not(.ant-drawer-footer .ant-btn-primary):focus-visible
        {
            outline:${(props) => `${props.theme.mode.secondary} auto 1px`};
            outline-offset: 4px;
        }
        .ant-drawer-footer span button:focus-visible, .ant-drawer-close:focus-visible, .ant-modal-close:focus-visible
        {
            outline:${(props) => `${props.theme.mode.secondary} auto 1px`};
            border-color:${(props) => `${props.theme.mode.secondary}`};
            outline-offset: -4px;
        }
        .ant-menu.ant-menu-root:focus-visible 
        {
            outline:${(props) => `${props.theme.mode.secondary} auto 1px !important`};
            box-shadow:${(props) => `0 0 0 0 ${props.theme.mode.secondary}`};
        }
        th:focus-visible, .ant-table-column-title:focus-visible, .pack-file-selection:focus-visible
        {
            outline:${(props) => `${props.theme.mode.secondary} auto 1px !important`};
        }
        
// :FOCUS-VISBLE CSS END

// :FOCUS CSS START

        span[role='img']:focus , span[role='img']:focus 
        {
            outline:${(props) => `${props.theme.mode.secondary} auto 1px !important`};
            outline-offset: 1px !important;
        }
         a:focus,   
        .ant-btn:not(.ant-drawer-footer .ant-btn-primary):focus
        {
            outline:${(props) => `${props.theme.mode.secondary} auto 1px !important`};
            outline-offset: 4px !important;
        }

        .ant-drawer-footer span button:focus, .ant-drawer-close:focus, .ant-modal-close:focus
        {
            outline:${(props) => `${props.theme.mode.secondary} auto 1px`};
            border-color:${(props) => `${props.theme.mode.secondary} !important`};
            outline-offset: -4px !important;
        }
       // .ant-menu.ant-menu-root:focus , 
        .pack-file-selection:focus
        {
            outline:${(props) => `${props.theme.mode.secondary} auto 1px !important`};
            box-shadow:${(props) => `0 0 0 0 ${props.theme.mode.secondary} !important`};
        }

// FOCUS CSS END
// tooltip 
.ant-tooltip {
    --antd-arrow-background-color: ${(props) => props.theme.mode.secondary};
}
.ant-dropdown-arrow::before {
    background: ${(props) => props.theme.mode.secondary}!important;
}
}
.clickableDropdown{
    width: 100%;
    display: block;
}
// virtual table css
    .ant-table-wrapper .ant-table-tbody-virtual .ant-table-row{
        font: 500 14px / 16px Montserrat;
    }
    .ant-select-single{
        height:100%
    }
`;

export default GlobalStyle;
