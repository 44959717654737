import { createSlice } from "@reduxjs/toolkit";

const machineSeriesSlice = createSlice({
  name: "machineSeries",
  initialState: { list: [] },
  reducers: {
    setMachineSeriesList: (machineSeries, action) => {
      machineSeries.list = [...action.payload];
    },
  },
});

export const { setMachineSeriesList } = machineSeriesSlice.actions;
export default machineSeriesSlice.reducer;
