import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../common/api";
import { notifier } from "@tintelligence/components";

export const getColorantsList = () => (dispatch, getState) => {
  const currentState = getState().entities.colorantSystems.data;
  const fetchColorantSystems = async () => {
    dispatch(
      apiCallBegan({
        url: `/dm/api/databasemanagement/colorantSystems`,
        method: "get",
        onSuccess: setColorantsList,
        onError: () => notifier.error("Could not fetch Dispense Instances"),
      })
    );
  };
  if (!currentState.length) {
    fetchColorantSystems();
  }
};

const colorantSystemsSlice = createSlice({
  name: "colorantSystems",
  initialState: {data:[],isLoaded:false},
  reducers: {
    setColorantsList: (colorantSystems, action) => {
      colorantSystems.data.splice(0, colorantSystems.length, ...action.payload.data);
      colorantSystems.isLoaded=true;
    },
  },
});

export const { setColorantsList } = colorantSystemsSlice.actions;
export default colorantSystemsSlice.reducer;
