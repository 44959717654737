import {
  DownloadOutlined,
  EditOutlined, ExclamationCircleOutlined, MoreOutlined, InfoCircleOutlined,LockOutlined
} from "../../Icons";
import { Modal, Tags, Tooltip, Typography, Tip, Spacer } from "@tintelligence/components";
import { DeleteIcon,DuplicateIcon } from "@tintelligence/icons";

import moment from "moment";
import React from "react";
import styled from "styled-components";
import { compareFormatedDates, formatBytes, formatDate, formatDateBasedOnUser } from "../../util/helper";
import ControlData from "./TIDispenseSetting/defaultDispenseSettingTabAndControl.json"

const { Paragraph } = Typography;

export const StyledActionBox = styled.div`
    display: flex;
    flex-direction: column;
    > span {
      cursor: pointer;
      padding: 2px;
      > .anticon { margin-right: 10px; }
    }
`;
const EllipsisContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${(props) => `${props.ellipWidth}` }}
  &.center-text {
    text-align: center;
  }
`;

export const columnFunctionsMap = {
  name: () => (text, record) => {
    return (
      <>
      {record && record.isInUse && (
        <LockOutlined style={{ marginRight: "5px" }} />
      )}
      <span style={{ fontWeight: 600 }}>{text}</span>
      </>
    );
  },
  fileSize: () => (text, record) => {
    if (!isNaN(text))
      return formatBytes(text)
  },
  tags: () => (text, record) => {
    if (text) {
      return (
        <Tags data={text.split(",")} closable={false} size="middle" />
      )
    }

  },
  uploadDate: (userSettings) => (text, record) => {
    const d = moment(text);
    // return <>{d.isValid() ? formatDate(d,dateFormat):null}</>;
    return <> {formatDateBasedOnUser(text, userSettings)}</>;
  },
  notes: () => (text, record) => {
    return (
      <Tooltip content={text} overlayInnerStyle={{ width: '250px' }}>
        <Paragraph ellipsis={{ rows: 2 }} >{text}</Paragraph>
      </Tooltip>
    )
  },
  actions: (messages, onDelete, onEditFileDetails, onDownload,fileType) => (text, record) => {
    const content = (
      <StyledActionBox className="more-actions">
       { (fileType == messages.FileTypeData[2].value || fileType == messages.FileTypeData[4].value) && <span onClick={() => onEditFileDetails(record, true)}>
            <DuplicateIcon style={{ marginLeft: "-3px" }} />
            {messages.duplicate}
          </span>}
        <span onClick={() => onDownload(record)}><DownloadOutlined />{messages.downloadBtn}</span>
        <span onClick={() => onEditFileDetails(record,false)}><EditOutlined />{messages.editBtn}</span>
        {!record.isInUse && <span onClick={() => onDelete(record)}><DeleteIcon />{messages.deleteBtn}</span>}
      </StyledActionBox>
    );
    return (
      <Tooltip content={content}>
        <MoreOutlined style={{ fontSize: 16 }} tabIndex={'0'} />
      </Tooltip>
    )
  },
  ellipsisText: () => (text) =>
    (
      <Tooltip content={text} placement="bottom">
        <EllipsisContainer>{text}</EllipsisContainer>
      </Tooltip>
    ),
};

export const setRenderedColumns = (
  messages, userPrefereces,
  columns,
  onDelete,
  onEditFileDetails,
  onDownload
) => {
  return columns.map((e) => {
    e = { ...e, title: messages.listView[e.dataIndex] }
    if (e.dataIndex === "fileName")
      return {
        ...e,
        render: columnFunctionsMap.name(),
      }
    else
      if (e.dataIndex === "fileSize") {
        return {
          ...e,
          render: columnFunctionsMap.fileSize(),
        }
      }
      else if (e.dataIndex === "uploadDate") {
        return {
          ...e,
          render: columnFunctionsMap[e.dataIndex](userPrefereces),
        }
      }
      else if (e.dataIndex === "tags") {
        return {
          ...e,
          render: columnFunctionsMap.tags()
        }
      }
      else if (e.dataIndex === "notes" || e.dataIndex === "packName") {
        return {
          ...e,
          render: columnFunctionsMap.ellipsisText()
        }
      }
      else if (e.dataIndex === "labelTypeText") {
        return {
          ...e,
          onFilter: (value, record) => {
            if (value) {
              return record.labelTypeText?.includes(value)
            } else {
              return record.labelTypeText == value
            }
          },
        }
      }
      else if (e.dataIndex === "actions") {
        return {
          ...e,
          render: columnFunctionsMap.actions(messages, onDelete,
            onEditFileDetails,
            onDownload)
        }
      }
      else return {...e};
  });
};


export const getFileColumns = (messages, userPrefereces, onDelete,
  onEditFileDetails,
  onDownload, fileType) => {
  if (fileType == messages.FileTypeData[4].value) {
    let columns = [
      {
        title: messages.listView.fileName,
        dataIndex: "fileName",
        visible: true,
        flex: "1 1 20%",
        filterable: true,
        render: columnFunctionsMap.name(),
        //sortable: true,
        //fixed: 'left',
        //width: 250
      },
      // {
      //   title: messages.listView.fileSize,
      //   dataIndex: "fileSize",
      //   render: columnFunctionsMap.fileSize(),
      //   visible: true,
      //   // filterable: true,
      //   sortable: true,
      //   sorter: (a, b) => {
      //     if (!a.fileSize) a.fileSize = 0;
      //     return Number(a.fileSize) - Number(b.fileSize)
      //   }
      // },
      {
        title: messages.listView.packName,
        dataIndex: "packName",
        visible: true,
        flex: "1 1 20%",
        sortable: true,
        filterable: true,
        render: columnFunctionsMap.ellipsisText()
      },
      {
        title: messages.listView.notes,
        dataIndex: "notes",
        visible: true,
        flex: "1 1 20%",
        render: columnFunctionsMap.ellipsisText()
      },
      {
        title: messages.listView.uploadDate,
        dataIndex: "uploadDate",
        visible: true,
        // width: "150px",
        flex: "1 1 20%",
        sorter: (a, b) =>
          compareFormatedDates(a.uploadDate, b.uploadDate, userPrefereces.dateFormat),
        render: columnFunctionsMap.uploadDate(userPrefereces),
        sortable: true,
        filterable: true,
        filterType: "date"
      },
      // {
      //   title: messages.listView.tags,
      //   dataIndex: "tags",
      //   width: 250,
      //   render: columnFunctionsMap.tags(messages),
      //   visible: true,
      //   //sortable: true,
      //   filterable: true
      // },

      {
        title: messages.listView.actions,
        dataIndex: "actions",
        render: columnFunctionsMap.actions(messages, onDelete,
          onEditFileDetails,
          onDownload, fileType),
        visible: true,
        //filterable: true,
        //sortable: true
      },
    ];
    return columns;
  } else {
    let columns = [
      {
        title: messages.listView.fileName,
        dataIndex: "fileName",
        visible: true,
        filterable: true,
        render: columnFunctionsMap.name(),
        flex: "1 1 20%",
        //sortable: true,
        //fixed: 'left',
        //width: 250
      },
      {
        title: messages.listView.fileSize,
        dataIndex: "fileSize",
        render: columnFunctionsMap.fileSize(),
        visible: true,
        flex: "1 1 10%",
        // filterable: true,
        sortable: true,
        sorter: (a, b) => {
          if (!a.fileSize) a.fileSize = 0;
          return Number(a.fileSize) - Number(b.fileSize)
        }
      },
      {
        title: messages.listView.uploadDate,
        dataIndex: "uploadDate",
        visible: true,
        // width: "150px",
        flex: "1 1 20%",
        sorter: (a, b) =>
          compareFormatedDates(a.uploadDate, b.uploadDate, userPrefereces.dateFormat),
        render: columnFunctionsMap.uploadDate(userPrefereces),
        sortable: true,
        filterable: true,
        filterType: "date"
      },
      {
        title: messages.listView.tags,
        dataIndex: "tags",
        // width: 250,
        flex: "1 1 20%",
        render: columnFunctionsMap.tags(messages),
        visible: true,
        //sortable: true,
        filterable: true,
        onFilter: (value, record) => {
         // console.log('tags filter',record)
          if(!record.tags)
          //  {console.log('tags filter-1',record)}
          if (value) {
            return record.tags?.includes(value)
          } else {
            return record.tags == value
          }
        },
      },
      {
        title: messages.listView.packName,
        dataIndex: "packName",
        visible: true,
        flex: "1 1 20%",
        sortable: true,
        filterable: true,
        render: columnFunctionsMap.ellipsisText()
      },
      {
        title: messages.listView.notes,
        dataIndex: "notes",
        flex: "1 1 20%",
        visible: true,
        render: columnFunctionsMap.ellipsisText()
        //render: columnFunctionsMap.notes()
      },
      {
        title: messages.listView.actions,
        dataIndex: "actions",
        render: columnFunctionsMap.actions(messages, onDelete,
          onEditFileDetails,
          onDownload, fileType),
        visible: true,
        //filterable: true,
        //sortable: true
      },
    ];
    if (fileType == messages.FileTypeData[2].value) {

      columns.splice(2, 0, {
        title: messages.listView.labelTypeText,
        dataIndex: "labelTypeText",
        flex: "1 1 20%",
        sortable: true,
        filterable: true,
        visible: true,
        sorter: (a, b) => {
          return ((a.labelTypeText < b.labelTypeText) ? -1 : ((a.labelTypeText > b.labelTypeText) ? 1 : 0));
        },
        onFilter: (value, record) => {
          if (value) {
            return record.labelTypeText?.includes(value)
          } else {
            return record.labelTypeText == value
          }
        },
      });

    }
    if (fileType == messages.FileTypeData[0].value) {
      columns.splice(1, 0, {
        title: messages.listView.formatTypeName,
        dataIndex: "formatTypeName",
        flex: "1 1 20%",
        sortable: true,
        filterable: true,
        visible: true,
        sorter: (a, b) => {
          const avalue = a.formatTypeName == null ? "" : a.formatTypeName;
          const bvalue = b.formatTypeName == null ? "" : b.formatTypeName;
          return ((avalue < bvalue) ? -1 : ((avalue > bvalue) ? 1 : 0));
        },
        onFilter: (value, record) => {
          if (value) {
            return record.formatTypeName?.includes(value)
          } else {
            return record.formatTypeName == value
          }
        },
      });
    }
    return columns;
  }
}

export const confirmModal = (messages, content, OkWarningMessage, closeWarningMessage) => {
  Modal.confirm({
    title: messages.ConfirmTitle,
    onOk: OkWarningMessage,
    onCancel: closeWarningMessage,
    icon: <ExclamationCircleOutlined />,
    content: content,
  });
};

export const getJsonData = (url) => {
 return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  })
    .then(response => {
      return response.json();
      //return ControlData;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const getLableWithToolTipInfoInfo = (lableName, toolTipMessage) =>
  <Spacer>
    {lableName}
    <Tooltip content={<p style={{ width: "300px", margin: 0 }} >{toolTipMessage}</p>} placement='topRight'>
      <InfoCircleOutlined style={{ color: '#aa9c9c' }} />
    </Tooltip>
  </Spacer>

export const cancelDragEnd = (data, originalIndex, destLaneNo) => {
  const destLane = data.find((pack) => pack.id === destLaneNo);
  const packDetails = { ...destLane.packDetails[0] };
  const updatedData = data.map((p) => {
    if (p.id === destLaneNo - 1) {
      const np = { ...p };
      np.packDetails = [...np.packDetails];
      np.packDetails.splice(originalIndex, 0, packDetails);
      return np;
    } else if (p.id === destLaneNo) {
      const np = { ...p };
      np.packDetails = np.packDetails.filter(
        (pd) => pd.packName != packDetails.packName
      );
      return np;
    }
    return p;
  });
  return updatedData;
};

export const onDragEnd = (result, data) => {
  const { source, destination } = result;
  const statusId = parseInt(source.droppableId);
  const sourceLane = data.find((pack) => pack.id === statusId);
  const sourcePacks = [...sourceLane.packDetails];
  const packDetails = { ...sourcePacks[source.index] };
  const updatedData = data.map((p) => {
    if (p.id === statusId) {
      const np = { ...p };
      np.packDetails = np.packDetails.filter(
        (pd) => pd.packName !== packDetails.packName
      );
      return np;
    } else if (p.id === statusId + 1) {
      const np = { ...p };
      const newPackDetails = {
        ...packDetails,
      };
      np.packDetails = [newPackDetails, ...np.packDetails];
      return np;
    }
    return p;
  });
  return updatedData;
};

export const scrollComponents = (parent, scroll) => {
  const el = parent.target,
    scrollTop = el.scrollTop;

  el.querySelectorAll(scroll).forEach((child) => {
    child.style.transform = `translateY(${scrollTop}px)`;
  });
};