import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../common/api";
import { notifier } from "@tintelligence/components";

export const checkCustomerBrandSupport = () => (dispatch, getState) => {
  const currentState = getState().entities.customerInfo;
  const fetchCustomerBrandSupport = async () => {
    dispatch(
      apiCallBegan({
        url: `/om/api/customermanagement/isCustomersSupportMultiBrand`,
        method: "get",
        onSuccess: setCustomerBrandSupport,
        onError: () => {notifier.error("Could not fetch Brand Instances")},
      })
    );
  };
  if (currentState.isMultiBrandSupport==undefined) {
    fetchCustomerBrandSupport();
  }
};

const customerBrandSupportSlice = createSlice({
  name: "customerInfo",
  initialState: {},
  reducers: {
    setCustomerBrandSupport: (customerInfo, action) => {
        customerInfo["isMultiBrandSupport"]= action.payload.data;
    },
  },
});

export const { setCustomerBrandSupport } = customerBrandSupportSlice.actions;
export default customerBrandSupportSlice.reducer;
