import React from "react";
import { Layout } from "../../components/ui";
import { Button } from "@tintelligence/components";
import { injectIntl } from "react-intl";
import styled from "styled-components";

const { Header } = Layout;

const StyledHeader = styled(Header)`
  background: ${(props) => props.theme.mode.mainBg};
  height: 55px !important;
  opacity: 1;
  padding: 0 0 0 15px !important;
  display: flex;
  align-items: center;
  & .header-icons {
    font-size: 17px;
    color: ${(props) => props.theme.mode.secondary};
    color: ${(props) => props.theme.mode.secondary};
    width: 50px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

const LoginHeader = ({ onClick, intl }) => {
  const messages = intl.messages.topBar;
  return (
    <StyledHeader className="header-style">
      <div style={{ width: "93px", marginLeft: "-15px", textAlign: "center" }}>
        <img
          // src={require("../../assets/images/datahub-logo.png")}
          src={`${process.env.REACT_APP_CDN_BLOB_STORAGE_URL}tintelligence-shared-assets/logos/datahub-logo.png`}

          height="45px"
          alt="TN"
        />
      </div>
      <div>
        <h2
          style={{
            margin: "0px  0px",
            font: "normal normal normal 19px/25px Montserrat",
          }}
        >
          {messages.TIDataHub}
        </h2>
      </div>
      <div style={{ marginLeft: "auto", marginRight: 25 }}>
        <Button type="primary" onClick={onClick}>
          {messages.loginBtn}
        </Button>
      </div>
    </StyledHeader>
  );
};

export default injectIntl(LoginHeader);
