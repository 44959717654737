import { BlobServiceClient } from "@azure/storage-blob";
import { formatDate } from "./helper";
import httpService from "./httpService";
// import { blobStorageUrl } from "../config/config.json";
import { notifier } from "@tintelligence/components";

const blobStorageUrl = process.env.REACT_APP_BLOB_STORAGE_URL;

const isBlobSasTokenValid = async (blobSasToken, containerName) => {
  try {
    const currDate = new Date();
    const sasExpiryDate = new Date(
      decodeURIComponent(
        blobSasToken.substring(
          blobSasToken.indexOf("&se") + 4,
          blobSasToken.indexOf("&srt")
        )
      )
    );
    if (sasExpiryDate - currDate < 1800000) return false;
    new BlobServiceClient(
      `${blobStorageUrl}${blobSasToken}`
    ).getContainerClient(containerName); //need to ask
    return true;
  } catch (e) {
    //console.log("Sas token expired", e);
    return false;
  }
};

const getValidBlobSasUrl = async (containerName) => {
  let blobSasToken = localStorage.getItem("blobSasToken");
  const isTokenValid = await isBlobSasTokenValid(blobSasToken, containerName);
  if (!isTokenValid) {
    const { data } = await httpService.get("/spm/api/blobstorage");
    blobSasToken = data.message;
    localStorage.setItem("blobSasToken", blobSasToken);
  }
  return `${blobStorageUrl}${blobSasToken}`;
};

const getValidBlobSasToken = async (containerName) => {
  let blobSasToken = localStorage.getItem("blobSasToken");
  const isTokenValid = await isBlobSasTokenValid(blobSasToken, containerName);
  if (!isTokenValid) {
    const { data } = await httpService.get("/spm/api/blobstorage");
    blobSasToken = data.message;
    localStorage.setItem("blobSasToken", blobSasToken);
  }
  return blobSasToken;
};

const getContainerClient = async (containerName) => {
  const blobSasUrl = await getValidBlobSasUrl(containerName);
  const blobServiceClient = new BlobServiceClient(blobSasUrl);
  return blobServiceClient.getContainerClient(containerName.toLowerCase());
};

export const uploadFileToStorage = async (
  file,
  containerName,
  folderName,
  onUploadProgress = () => {},
  fileName
) => {
  try {
    const fileSize = file.size;
    const containerClient = await getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(
      `${folderName}/${fileName || file.name}`
    );
    return blockBlobClient.uploadBrowserData(file, {
      onProgress: ({ loadedBytes }) => {
        onUploadProgress({ percent: (loadedBytes / fileSize) * 100 });
      },
    });
  } catch (e) {
    notifier.error("Upload to cloud failed!!");
    return false;
  }
};

export const getServicePacksForAMachineSeriesFromStorage = async (
  containerName,
  folderName
) => {
  const containerClient = await getContainerClient(containerName);
  const servicePacks = [];
  let iter = containerClient.listBlobsByHierarchy("/", {
    prefix: `${folderName}/`,
    includeMetadata: true,
  });
  for await (let {
    name,
    kind,
    metadata: {
      isPublished = "false",
      previousrequiredversion: previousRequiredVersion = "",
    },
    properties: { createdOn: uploadDate, lastModified },
  } of iter) {
    if (kind === "blob") {
      const servicePack = name.substring(name.lastIndexOf("/") + 1);
      isPublished = isPublished.toLocaleLowerCase() === "true" ? true : false;
      const servicePackInfo = {
        servicePack,
        isPublished,
        previousRequiredVersion,
        uploadDate,
        publishDate: isPublished ? lastModified : null,
      };
      servicePacks.push(servicePackInfo);
    }
  }
  return servicePacks
    .sort((p1, p2) => p2.uploadDate - p1.uploadDate)
    .map((pack) => ({
      ...pack,
      //uploadDate: formatDate(pack.uploadDate, "DD/MM/YYYY"),
      //publishDate: pack.publishDate && formatDate(pack.publishDate, "DD/MM/YYYY"),
    }));
};

export const updateMetadataPropertyForABlob = async (
  containerName,
  folderName,
  fileName,
  previousRequiredVersion
) => {
  try {
    const containerClient = await getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(
      `${folderName}/${fileName}`
    );
    blockBlobClient.setMetadata({ previousRequiredVersion });
  } catch (e) {
    notifier.warn(
      `Error occured while setting the property` + previousRequiredVersion
    );
  }
};

export const createFolder = async (
  containerName,
  parentFolderPath,
  folderName,
  metaInfoText = "Dummy file for Folder meta"
) => {
  try {
    const containerClient = await getContainerClient(containerName);
    const file = new File([metaInfoText], `metaInfo.txt`, {
      type: "text/plain",
      lastModified: new Date(),
    });
    const blockBlobClient = containerClient.getBlockBlobClient(
      `${parentFolderPath}/${folderName}/${file.name}`
    );
    await blockBlobClient.uploadBrowserData(file);
    return true;
  } catch (e) {
    notifier.warn(`Error occured while creating folder` + parentFolderPath);
    return false;
  }
};

export const getFilesIteratorForAFolder = async (containerName, folderPath) => {
  const containerClient = await getContainerClient(containerName);
  return containerClient.listBlobsByHierarchy("/", {
    prefix: folderPath,
    includeMetadata: true,
  });
};

export const getSubFoldersIteratorForAFolder = async (
  containerName,
  parentFolder
) => {
  const containerClient = await getContainerClient(containerName);
  return containerClient.listBlobsByHierarchy("/", {
    prefix: parentFolder,
  });
};

export const deleteFolder = async (containerName, parentFolder, folderName) => {
  try {
    const containerClient = await getContainerClient(containerName);
    let iter = containerClient.listBlobsByHierarchy("/", {
      prefix: `${parentFolder}/${folderName}/`,
    });
    for await (const { name } of iter) {
      containerClient.deleteBlob(name);
    }
    //notifier.success(`Folder - ${folderName} deleted successfully`);
    return true;
  } catch (e) {
    notifier.warn(`Error occured while deleting folder ${folderName}`);
    return false;
  }
};

export const deleteFile = async (containerName, fileName, filePath) => {
  try {
    const containerClient = await getContainerClient(containerName);
    await containerClient.deleteBlob(filePath);
    notifier.success(`File - ${fileName} deleted successfully`);
    return true;
  } catch (e) {
    notifier.warn(`Error occured while deleting file ${fileName}`);
    return false;
  }
};

export const getDownloadUrlForAFile = async (
  containerName,
  folderName,
  fileName
) => {
  const sasToken = await getValidBlobSasToken(containerName);
  const url = `${blobStorageUrl}${containerName}/${folderName}/${fileName}${sasToken}`;
  return url;
};

export const getBlobStorageUrlForAFile = (
  containerName,
  folderName,
  fileName
) => {
  return `${blobStorageUrl}${containerName}/${folderName}/${fileName}`;
};

export const fileDownloadHandler = (url, name = "") => {
  if (url) {
    fetch(url).then(function (t) {
      return t.blob().then((b) => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", name);
        a.click();
      });
    });
  }
};

export const setMetadataPropertiesForABlob = async (
  containerName,
  folderName,
  fileName,
  properties
) => {
  try {
    const containerClient = await getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(
      `${folderName}/${fileName}`
    );
    await blockBlobClient.setMetadata({ ...properties });
  } catch (e) {
    console.log(e);
  }
};

export const getFolderMetadata = async (containerName, folderPath) => {
  try {
    const containerClient = await getContainerClient(containerName);
    let iter = await containerClient.listBlobsByHierarchy("/", {
      prefix: `${folderPath}metaInfo.txt`,
      includeMetadata: true,
    });
    const folderMetadata = {};
    for await (const { name, metadata } of iter) {
      folderMetadata.filesCount = Number(metadata.filescount || 0);
      folderMetadata.folderSize = Number(metadata.foldersize || 0);
      folderMetadata.folderDisplayName = metadata.folderdisplayname;
      folderMetadata.lastModified = metadata.lastmodified;
      break;
    }
    return folderMetadata;
  } catch (e) {
    console.log(e);
    return {};
  }
};
export const deleteFileSliently = async (containerName, fileName, filePath) => {
  try {
    const containerClient = await getContainerClient(containerName);
    await containerClient.deleteBlob(filePath);
    return true;
  } catch (e) {
    return false;
  }
};